import { Box, Textarea } from "@tempo/core";
import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";

type Props = {
  code: string;
};

const CopyCodeArea: React.FC<Props> = ({ code }) => {
  const [tooltipVisible, setTooltipVisibility] = useState(false);
  const [ref, setRef] = useState<HTMLTextAreaElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(ref, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement, padding: 30 } },
      {
        name: "offset",
        options: {
          offset: [0, 14],
        },
      },
    ],
    placement: "top-end",
  });
  const timeoutRef = useRef<NodeJS.Timeout>();
  const visibleTimeoutRef = useRef<NodeJS.Timeout>();

  const onClick = () => {
    if (!ref || !document || !document.execCommand) return;

    ref?.select();
    document.execCommand("copy");

    const showTooltip = () => {
      setTooltipVisibility(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setTooltipVisibility(false);
      }, 5000);
    };

    if (tooltipVisible) {
      setTooltipVisibility(false);

      if (visibleTimeoutRef.current) {
        clearTimeout(visibleTimeoutRef.current);
      }
      visibleTimeoutRef.current = setTimeout(showTooltip, 150);
    } else {
      showTooltip();
    }
  };

  return (
    <>
      <Textarea
        ref={setRef}
        mt="4"
        p="2"
        w="full"
        bg="gray.100"
        border="2px"
        borderColor="gray.500"
        cursor="pointer"
        borderRadius="md"
        readOnly
        rows={3}
        style={{ resize: "none" }}
        value={code}
        onClick={onClick}
      />
      {tooltipVisible && (
        <PopperElement
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          p="2"
          color="white"
          bg="#63b3ed"
          borderRadius="md"
          fontWeight="semibold"
          shadow="md"
          cursor="pointer"
          onClick={() => setTooltipVisibility(false)}
        >
          Code copied to clipboard !
          <Arrow
            ref={setArrowElement}
            style={styles.arrow}
            className="shadow"
          />
        </PopperElement>
      )}
    </>
  );
};

export default CopyCodeArea;

const PopperElement = styled(Box)``;

const Arrow = styled.div`
  position: relative;
  background: #63b3ed;
  bottom: 0px;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(18, 24, 28, 0);
    border-top-color: #63b3ed;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #63b3ed;
    border-width: 11px;
    margin-left: -11px;
  }
`;
