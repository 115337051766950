import { Input } from "@tempo/core";
import { deezerApi } from "@widget/contexts/api";
import { Field, useField } from "formik";
import type { FormEvent } from "react";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

type Props = {
  name: string;
} & React.HTMLProps<HTMLInputElement>;

type OembedResponse = {
  url: string;
};

const URLField: React.FC<Props> = ({ name }) => {
  const [field, , helpers] = useField(name);

  // Use a local value, because resolving the deezer url
  // can be async - we don't want to reflect that latency in the
  // user input
  const [localValue, setLocalValue] = useState(field.value);

  // When local value changes, perform a query
  // if url is not shortened, query is success instantly
  // if url is shortened, ajax fires
  useQuery(
    ["deezer-url", localValue],
    async ({ queryKey }) => {
      const lValue = queryKey[1];
      if (lValue.includes("deezer.page.link")) {
        const response = await deezerApi.get(`/oembed?url=${lValue}`);
        const data = response.data as OembedResponse;
        return data.url;
      }
      return lValue;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: (deezerUrl: string) => {
        helpers.setValue(deezerUrl);
      },
    }
  );

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setLocalValue(value);
  };

  return (
    <Field
      as={Input}
      mt="4"
      type="text"
      placeholder="Paste your deezer.com content URL here"
      name={name}
      data-testid="widget-portal-url-field"
      onChange={handleChange}
      value={localValue}
    />
  );
};

export default URLField;
